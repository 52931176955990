@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .ReactVirtualized__Grid__innerScrollContainer {
    min-width: 100%;
  }

  .ReactVirtualized__List {
    min-width: 100%;
  }
}

@layer components {
  #tgp-body .typo-subtitle2 {
    font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.00714rem;
    line-height: 1.57;
  }

  #tgp-body .typo-body1 {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 0.8571428571428571rem;
    font-weight: 400;
    letter-spacing: 0.00938em;
    line-height: 1.5;
  }

  #tgp-body .typo-overline {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 0.6428571428571428rem;
    font-weight: 400;
    letter-spacing: 0.08333em;
    line-height: 2.66;
    text-transform: uppercase;
  }

  #tgp-body .typo-button {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.02857em;
    line-height: 1.75;
    text-transform: uppercase;
  }
}
