.m0 > * + * {
  @apply ml-0;
}

.m0_5 > * + * {
  @apply ml-1;
}

.m1 > * + * {
  @apply ml-2;
}

.m2 > * + * {
  @apply ml-4;
}

.m3 > * + * {
  @apply ml-6;
}

.m4 > * + * {
  @apply ml-8;
}

.m5 > * + * {
  margin-left: 2.5rem;
}

.align-top {
  @apply items-start;
}

.align-center {
  @apply items-center;
}

.align-bottom {
  @apply items-end;
}
