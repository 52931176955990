.m0 > * + * {
    @apply mt-0;
}

.m1 > * + * {
    @apply mt-2;
}

.m2 > * + * {
    @apply mt-4;
}

.m3 > * + * {
    @apply mt-6;
}

.m4 > * + * {
    @apply mt-8;
}

.m5 > * + * {
    @apply mt-10;
}

.m6 > * + * {
    @apply mt-12;
}