.root {
  overflow: hidden;
}
.root span {
  overflow: visible;
}

.dft-primary {
  @apply text-primary-contrastText bg-primary-main;
}

.dft-secondary {
  @apply text-secondary-contrastText bg-secondary-main;
}

.dft-error {
  @apply text-error-contrastText bg-error-main;
}

.dft-success {
  @apply text-success-contrastText bg-success-main;
}

.dft-warning {
  @apply text-warning-contrastText bg-warning-main;
}

.out-primary {
  @apply text-primary-main border-primary-main;
}

.out-secondary {
  @apply text-secondary-main border-secondary-main;
}

.out-error {
  @apply text-error-main border-error-main;
}

.out-success {
  @apply text-success-main border-success-main;
}

.out-warning {
  @apply text-warning-main border-warning-main;
}
