.listItem {
  @apply relative select-none flex border-b border-solid border-divider min-w-max;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.listItem::-webkit-scrollbar {
  display: none;
}

.listItem > *:not(:last-child) {
  @apply mr-2;
}

.listItem > *:first-child {
  @apply ml-2;
}

.listItem button {
  @apply p-0;
}

.listItem:hover {
  @apply cursor-pointer bg-action-hover shadow-12 text-text;
}

.listItem[aria-selected='true'] {
  @apply text-text;
  transition: all 250ms ease-out;
  background: theme('colors.action.selected');
  animation: cv-item-glow 250ms ease-out;
  animation-fill-mode: forwards;
}

.listItem:focus {
  outline: none;
}

.listItem:hover .quickActions {
  @apply opacity-100;
}

.listItem:not(:hover) .quickActions {
  @apply opacity-0;
}

@keyframes cv-item-glow {
  0% {
    @apply shadow-glowFrom;
  }
  100% {
    @apply shadow-glowTo;
  }
}

.quickActions {
  transition: all 150ms;
}

.titleBar {
  @apply -mb-4 flex shadow-4 overflow-x-scroll overflow-y-hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.titleBar::-webkit-scrollbar {
  display: none;
}

.titleBar > *:not(:last-child) {
  @apply mr-2;
}

.titleBar > *:first-child {
  @apply ml-2;
}

.root {
  @apply py-2 px-1 flex flex-col justify-center;
}

.vertical {
  @apply flex items-center;
}

.vertical > *:not(:last-child) {
  @apply mr-2;
}

.main {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8571428571428571rem;
  font-weight: 400;
  letter-spacing: 0.00938em;
  line-height: 1.5;
  @apply pb-0 mb-0;
}

.sub {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.6428571428571428rem;
  font-weight: 400;
  letter-spacing: 0.08333em;
  line-height: 2.66;
  text-transform: uppercase;
  @apply leading-none text-grey-700;
}
