.phoneNoListItem:hover .phoneNoActions {
  @apply visible opacity-100;
}

.phoneNoListItem {
  @apply h-12;
}

.phoneNoActions {
  @apply invisible opacity-0 absolute top-1/2 transform -translate-y-1/2 right-10;
}