
.value {

}

.value_edit {
	opacity: 0.5;
}

.value:hover .value_edit {
	opacity: 1;
}