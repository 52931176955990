.card {
  min-height: 256px;
  @apply relative;
}

.card > * {
  min-height: inherit;
}

.spinner {
  @apply absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-15 h-15;
}

.cell {
  @apply border border-solid border-divider whitespace-nowrap;
}

.cell:first-child {
  border-left: none;
}

.cell:last-child {
  border-right: none;
}
