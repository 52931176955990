.group > div:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.filled_default > div {
    @apply bg-primary-light text-white;
}

.filled_danger > div {
    @apply bg-error-light text-white;
}

.filled_success > div {
    @apply bg-success-light text-white;
}

.filled_warning > div {
    @apply bg-warning-light;
}

.filled_grey > div {
    @apply bg-grey-200;
}

.filled_grey_light > div {
    @apply bg-grey-100;
}

.filled_warning > div {
    @apply bg-warning-main text-white;
}

.filled_success > div {
    @apply bg-success-main text-white;
}
