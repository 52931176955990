.listItem {
  color: theme('colors.text.darkPrimary');
}

.listItem > * {
  gap: unset;
}

.nested {
  padding-left: 42px;
}

.nested:not(:hover):not(.active) span {
  opacity: 0.75;
}

.active {
  background: theme('colors.background.darkDefault');
}
