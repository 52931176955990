.listItem {
  @apply relative border-b border-solid border-divider flex;
}

.listItem:first-child {
  @apply border-t border-solid border-divider;
}

.listItem > * + *:not(.MuiTouchRipple-root) {
  @apply ml-4;
}

.listItem > *:first-child {
  @apply ml-2;
}

.listItem[aria-selected='true'] {
  @apply bg-action-selected;
  transition: all 250ms ease-out;
  animation: tg-list-item-glow 250ms ease-out;
  animation-fill-mode: forwards;
}

@keyframes tg-list-item-glow {
  0% {
    @apply shadow-glowFrom;
  }
  100% {
    @apply shadow-glowTo;
  }
}

.listItemButton:hover {
  @apply cursor-pointer bg-action-hover shadow-12;
}
