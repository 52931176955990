.primary {
    @apply text-primary-main
}

.disabled {
    @apply text-grey-100
}

.secondary {
    @apply text-secondary-main
}

.warning {
    @apply text-warning-main
}