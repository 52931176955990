.decorator {
  @apply relative mb-2;
}

.decorator::before {
  @apply w-12 left-0 bg-primary-main absolute;
  content: ' ';
  height: 3px;
  bottom: -3px;
}
