.root {
  @apply grid;
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.col-5 {
  grid-template-columns: repeat(5, 1fr);
}

.gap-2 {
  @apply gap-4;
}
