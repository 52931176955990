.nws-hud-btn {
  margin: 6px;
  padding: 8px;
  width: 48px;
  height: 48px;
  background: white;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

  outline: none;
  border: none;
  border-radius: 50%;
  user-select: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  font-weight: 600;
  letter-spacing: 0.02857em;
  color: rgba(0, 0, 0, 0.54);

  display: flex;
  align-items: center;
  justify-content: center;
}

.nws-hud-btn:hover {
  background: rgb(216, 216, 216);
  cursor: pointer;
}

.nws-hud-btn:active {
  background: rgb(200, 200, 200);
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.nws-hud-btn.small {
  width: 40px;
  height: 40px;
}

.nws-hud-btn.active {
  background: #f50057;
  color: white;
}
